export const domain = window.location.origin;
export const website = "https://uapp.uk/";

export const InputType = {
  Text: 1,
  TextArea: 2,
  SelectList: 3,
  Radio: 4,
  CheckBox: 5,
};

export const userTypeId = {
  SystemAdmin: 1,
  Admin: 2,
  AdmissionManager: 3,
  Provider: 4,
  BranchAdmin: 5,
  Student: 6,
  AccountManager: 7,
  Editor: 8,
  FinanceManager: 9,
  AccountOfficer: 10,
  ComplianceManager: 11,
  ProviderAdmin: 12,
  Consultant: 13,
  AdmissionOfficer: 14,
  ComplianceOfficer: 15,
  ProviderCompliance: 16,
  AdmissionCompliance: 17,
  BranchManager: 22,
};
