import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Post } from "../../../api/method";
import { toast } from "react-toastify";
import Heading from "../../../components/ui/Heading";
import Loading from "../../../components/ui/Loading";
import MetaPages from "./MetaPages";
import { AdminUsers, BranchAdminUsers } from "../../../components/User";

const MetaAdd = () => {
  const url = AdminUsers()
    ? "/DefaultMetaConnection"
    : BranchAdminUsers()
    ? "/BranchMetaConnection"
    : "/ConsultantMetaConnection";

  const location = useLocation();
  const [isTokenSubmit, setIsTokenSubmit] = useState(false);
  const [pageList, setPageList] = useState({});
  const { mutateAsync } = Post();
  const currentHash = location.hash;
  const connectionDataArray = currentHash.split("&");

  const onTokenSubmit = () => {
    setIsTokenSubmit(true);
    const submitData = {
      access_token: connectionDataArray[0]?.split("=")[1],
      expiration_time: connectionDataArray[1]?.split("=")[1],
      expires_in: connectionDataArray[2]?.split("=")[1],
      long_lived_token: connectionDataArray[3]?.split("=")[1],
    };
    mutateAsync({
      path: url,
      formData: submitData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          setPageList(res.data.data);
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });
    setIsTokenSubmit(false);
  };

  useEffect(() => {
    if (currentHash && isTokenSubmit === true) {
      onTokenSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHash, isTokenSubmit]);

  useEffect(() => {
    setIsTokenSubmit(true);
  }, []);

  if (!pageList?.metaPages) return <Loading />;

  return (
    <>
      <Heading text="Check your facebook pages to connect" />

      <MetaPages ismodal={false} pages={pageList} mutateAsync={mutateAsync} />
    </>
  );
};

export default MetaAdd;
