import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Put } from "../../api/method";
import SubHeading from "../../components/ui/SubHeading";
import DDFilterByAppUrl from "../../components/ui/DDFilterByAppUrl";
import { AdminUsers, BranchId } from "../../components/User";

const schema = yup.object({
  consultantId: yup.number(),
});

const AssignForm = ({ refetch }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const { mutateAsync } = Put();
  const [branchId, setBranchId] = useState(BranchId());
  const [consultantId, setConsultantId] = useState(0);
  const [consultantIdError, setConsultantIdError] = useState("");

  const defaultData = {
    consultantId: 0,
  };

  const { handleSubmit, reset } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    if (consultantId === 0) {
      setConsultantIdError("Please select a consultant");
    } else {
      setConsultantIdError("");
      setIsSubmit(true);
      mutateAsync({
        path: `Consultant/Assign?id=${consultantId}&branchid=${branchId}`,
        formData: {},
      })
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            reset();
            refetch();
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((error) => {
          toast.error("Error:", error.message);
        });
      setIsSubmit(false);
    }
  };

  return (
    <div className="card mb-4">
      <SubHeading text="Select consultant form uapp to work on lead management" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {AdminUsers() && (
            <Col md={4}>
              {AdminUsers() && (
                <DDFilterByAppUrl
                  label=""
                  placeholder="Select Branch"
                  url="event/BranchApi/Index"
                  defaultValue={branchId}
                  action={setBranchId}
                  className="mb-24px me-2"
                />
              )}
            </Col>
          )}
          <Col md={5}>
            <DDFilterByAppUrl
              label=""
              placeholder="Select Consultant"
              url={`event/ConsultantApi/get-employee-consultants?branchid=${branchId}`}
              name="consultantId"
              defaultValue={consultantId}
              error={consultantIdError}
              setError={setConsultantIdError}
              action={setConsultantId}
            />
          </Col>
          <Col md={3}>
            <ButtonPrimary
              className="w-100"
              text="Assign"
              buttonStatus={isSubmit}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AssignForm;
