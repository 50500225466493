import React, { useState } from "react";
import { Get } from "../../../api/method";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import MetaConnect from "./MetaConnect";
import EditBtn from "../../../components/buttons/EditBtn";
import { AdminUsers, BranchAdminUsers } from "../../../components/User";
// import CheckSwitch from "../../../components/ui/CheckSwitch";
// import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import MetaEdit from "./MetaEdit";

const Meta = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const url = AdminUsers()
    ? "/DefaultMetaConnection"
    : BranchAdminUsers()
    ? "/BranchMetaConnection"
    : "/ConsultantMetaConnection";

  // const { mutateAsync } = Put();
  const { data, error, isLoading, isError, refetch } = Get("key", url);

  // const onSubmit = (id) => {
  //   mutateAsync({
  //     path: "",
  //     formData: "",
  //   })
  //     .then((res) => {
  //       if (res.data.isSuccess === true) {
  //         toast.success(res.data.message);
  //       } else {
  //         toast.warn(res.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error("Error:", error.message);
  //     });
  // };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  return (
    <>
      {data?.data ? (
        <div className="custom-border pl-16px pr-16px pt-12px pb-12px mb-16px">
          <p className="d-flex justify-content-between">
            <b>Connected to Meta with following pages</b>
            {/* <ButtonPrimary text="Re-Assign" action={() => setModalOpen(true)} /> */}
            <EditBtn action={() => setModalOpen(true)} />
          </p>
          {data?.data?.metaPages?.length > 0 &&
            data?.data?.metaPages?.map((item, i) => (
              <p key={i} className="d-flex justify-content-between">
                {i + 1}. {item.name}
                {/* <CheckSwitch
                  register={() => {}}
                  defaultValue={item?.is_checked}
                  action={() => onSubmit(item?.id)}
                /> */}
              </p>
            ))}
        </div>
      ) : (
        <MetaConnect />
      )}

      <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
        <Modal.Body>
          <MetaEdit refetch={refetch} action={() => setModalOpen(false)} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Meta;
