export const uappUrl = `https://api.uapp.uk/`;
// export const uappUrl = `http://localtest.uapp.uk/`;
// export const uappUrl = `https://localhost:44357/`;
//export const uappUrl = `http://192.168.0.15:45455/`;

export const rootUrl = `https://leadapi.uapp.uk/`;
// export const rootUrl = `https://earlytealphone48.conveyor.cloud/`;
// export const rootUrl = `https://localhost:7096/`;
//export const rootUrl = `http://192.168.0.15:45456/`;

export const webUrl = `https://webapi.uapp.uk/`;
// export const webUrl = `https://littlesparklybag51.conveyor.cloud/`;
// export const webUrl = `https://localhost:44359/`;
// export const webUrl = `http://192.168.0.138:45461/`;

export const rootApi = `${rootUrl}api/v1/`;
export const userApi = `${rootUrl}api/`;
export const webApi = `${webUrl}api/`;
