import React from "react";
import NavMenu from "./NavMenu";
import mainmenuAdmin from "../menus/mainmenuAdmin";
import mainmenu from "../menus/mainmenu";
import mainmenuConsultant from "../menus/mainmenuConsultant";
import { AdminUsers, BranchAdminUsers, Consultant } from "../User";
import { useContextData } from "../../api/context";
import mainmenuLead from "../menus/mainmenuLead";
import mainmenuEvent from "../menus/mainmenuEvent";
import leadMenuaffiliate from "../menus/leadMenuaffiliate";
import leadMenuConsultants from "../menus/leadMenuConsultants";
import leadMenuPartners from "../menus/leadMenuPartners";
import leadMenuProviders from "../menus/leadMenuProviders";
import mainmenuBranch from "../menus/mainmenuBranch";

const HeaderMenu = ({ action }) => {
  const value = useContextData();

  const menu =
    value.user.email === "leadmanager@mail.com"
      ? mainmenuLead
      : value.user.email === "eventmanager@mail.com"
      ? mainmenuEvent
      : value.user.email === "affiliateambassadorleadmanager@gmail.com"
      ? leadMenuaffiliate
      : value.user.email === "consultantleadmanager@gmail.com"
      ? leadMenuConsultants
      : value.user.email === "partnerleadmanager@gmail.com"
      ? leadMenuPartners
      : value.user.email === "providerleadmanager@gmail.com"
      ? leadMenuProviders
      : AdminUsers()
      ? mainmenuAdmin
      : BranchAdminUsers()
      ? mainmenuBranch
      : Consultant()
      ? mainmenuConsultant
      : mainmenu;
  return (
    <>
      {menu?.map((item, i) => (
        <NavMenu
          key={i}
          title={item.title}
          Icon={item.Icon}
          path={item.path}
          action={action}
        />
      ))}
    </>
  );
};

export default HeaderMenu;
