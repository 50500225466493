import React from "react";
import { Get, Put } from "../../../api/method";
import MetaPages from "./MetaPages";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import Heading from "../../../components/ui/Heading";
import BackBtn from "../../../components/buttons/BackBtn";
import { AdminUsers, BranchAdminUsers } from "../../../components/User";
import CloseBtn from "../../../components/buttons/CloseBtn";
import SubHeading from "../../../components/ui/SubHeading";

const MetaEdit = ({ refetch, action }) => {
  const { mutateAsync } = Put();
  const url = AdminUsers()
    ? "MetaPage/RetriveForDefaultConnection"
    : BranchAdminUsers()
    ? "MetaPage/RetriveForBranchConnection"
    : "MetaPage/RetriveForConsultantConnection";

  const { data, error, isLoading, isError } = Get("key", url);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  return (
    <>
      {action ? (
        <div className="d-flex justify-content-between">
          <SubHeading text="Check your facebook pages to connect" />
          <CloseBtn action={action} />
        </div>
      ) : (
        <>
          <BackBtn path="/lead/setting" />
          <Heading text="Check your facebook pages to connect" />
        </>
      )}

      <MetaPages
        pages={data?.data}
        mutateAsync={mutateAsync}
        refetch={refetch}
        action={action}
      />
    </>
  );
};

export default MetaEdit;
