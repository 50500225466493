import React from "react";
import { Get } from "../../../api/method";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import { BsMeta } from "react-icons/bs";
import ConnectButton from "../../UI/ConnectButton";
import { AdminUsers, BranchAdminUsers } from "../../../components/User";

const MetaConnect = () => {
  const pathname = window?.location?.pathname;
  const url = AdminUsers()
    ? `/DefaultMetaConnection/prepare?source=${
        pathname === "/leadSetting" ? "uapp" : ""
      }`
    : BranchAdminUsers()
    ? `/BranchMetaConnection/prepare?source=${
        pathname === "/leadSetting" ? "uapp" : ""
      }`
    : `/ConsultantMetaConnection/prepare?source=${
        pathname === "/leadSetting" ? "uapp" : ""
      }`;

  const { data, error, isLoading, isError } = Get("key", url);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  return (
    <>
      <ConnectButton
        Icon={BsMeta}
        iconColor="#0866FF"
        text="Meta"
        url={data?.data}
      />
    </>
  );
};

export default MetaConnect;
