import {
  AiOutlineAppstore,
  AiOutlineUnorderedList,
  AiOutlineUser,
} from "react-icons/ai";
// import { FiDatabase, FiTrendingUp } from "react-icons/fi";
import {
  // MdOutlineCampaign,
  // MdOutlineFormatListNumbered,
  MdOutlineSettings,
} from "react-icons/md";

const leadMenuBranch = [
  {
    title: "Dashboard",
    Icon: AiOutlineAppstore,
    path: "/lead",
  },
  {
    title: "Student Lead",
    Icon: AiOutlineUnorderedList,
    path: "/lead/list",
  },
  {
    title: "Consultant",
    Icon: AiOutlineUser,
    path: "/lead/assignConsultant",
  },
  // {
  //   title: "Source",
  //   Icon: FiDatabase,
  //   path: "/lead/source",
  // },
  // {
  //   title: "Performance",
  //   Icon: FiTrendingUp,
  //   path: "/lead/performance",
  // },
  // {
  //   title: "Campaign",
  //   Icon: MdOutlineCampaign,
  //   path: "/lead/campaign",
  // },
  {
    title: "Setting",
    Icon: MdOutlineSettings,
    path: "/lead/setting",
  },
];

export default leadMenuBranch;
