import { useContextData } from "../api/context";
import { userTypeId } from "./Constant";

export const ReferenceId = () => {
  const value = useContextData();
  const result = value.user.referenceId;
  return result;
};

export const BranchId = () => {
  const value = useContextData();
  const result = value.user.branchId;
  return result;
};

export const SystemAdmin = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.SystemAdmin;
  return result;
};

export const Admin = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.Admin;
  return result;
};

export const AdmissionManager = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.AdmissionManager;
  return result;
};

export const Provider = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.Provider;
  return result;
};

export const BranchAdmin = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.BranchAdmin;
  return result;
};

export const BranchManager = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.BranchManager;
  return result;
};

export const Student = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.Student;
  return result;
};

export const AccountManager = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.AccountManager;
  return result;
};

export const Editor = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.Editor;
  return result;
};

export const FinanceManager = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.FinanceManager;
  return result;
};

export const AccountOfficer = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.AccountOfficer;
  return result;
};

export const ComplianceManager = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.ComplianceManager;
  return result;
};

export const ProviderAdmin = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.ProviderAdmin;
  return result;
};

export const Consultant = () => {
  const value = useContextData();
  const result = value?.user?.userTypeId === userTypeId?.Consultant;
  return result;
};

export const AdmissionOfficer = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.AdmissionOfficer;
  return result;
};

export const ComplianceOfficer = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.ComplianceOfficer;
  return result;
};

export const ProviderCompliance = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.ProviderCompliance;
  return result;
};

export const AdmissionCompliance = () => {
  const value = useContextData();
  const result = value.user.userTypeId === userTypeId.AdmissionCompliance;
  return result;
};

// export const AdminUsers = () => SystemAdmin() || Admin();
export const AdminUsers = () => (SystemAdmin() || Admin()) && BranchId() === 1;
export const BranchAdminUsers = () => BranchAdmin() || BranchManager();
