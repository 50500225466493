import { FiCalendar, FiTarget } from "react-icons/fi";
import { FaWpforms } from "react-icons/fa";
import settingMenu from "./settingMenu";
import leadMenuBranch from "./leadMenuBranch";

const mainmenuBranch = [
  {
    title: "Lead",
    Icon: FiTarget,
    path: "/lead",
    submenu: leadMenuBranch,
  },

  {
    title: "Event",
    Icon: FiCalendar,
    path: "/event",
  },

  {
    title: "Form Builder",
    Icon: FaWpforms,
    path: "/formbuild",
    submenu: settingMenu,
  },
];

export default mainmenuBranch;
